export const ACTION_TYPES = {
  SET_TARGETING_SOUND: 'SET_TARGETING_SOUND',
  SET_TARGETING_PICTURE: 'SET_TARGETING_PICTURE'
};

const setTargetingSound = (source, title, description) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_TARGETING_SOUND,
    source,
    title,
    description
  });
};

const setTargetingPicture = (source, title, description) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_TARGETING_PICTURE,
    source,
    title,
    description
  });
};

export default {
  setTargetingSound,
  setTargetingPicture
};
