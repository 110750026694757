import { createBrowserHistory } from 'history';
import { gsap, TweenMax } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export const history = createBrowserHistory();

export const scrollTo = (yPosition, ele = window) => {
  if (process.browser) {
    TweenMax.to(ele, .3, {
      scrollTo: yPosition
    });
  }
};

export default {};
