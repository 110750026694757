import React from 'react';

import Intro from './Intro';
import About from './About';
import What from './What';
import RecentWorks from './RecentWorks';
import Contacts from './Contacts';

import './Home.scss';

function Home() {
  return (
    <div id="home">
      <Intro>
        <About />
        <What />
        <RecentWorks />
        <Contacts />

        <footer>
          © 2020 cuongxoi. All rights reserved.
        </footer>
      </Intro>
    </div>
  );
}

export default Home;
