import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as ScrollMagic from 'scrollmagic';

import { CATEGORIES } from 'consts';

import orgChart from 'assets/img/works/org-chart.jpg';
import magicBoard from 'assets/img/works/magic-board.jpg';
import asbnbook from 'assets/img/works/asbnbook.jpg';
import wabisabi from 'assets/img/works/underdogs-2-wabi-sabi.jpg';
import asbnMv from 'assets/img/works/asbn-mv.jpg';
import badDream from 'assets/img/works/bad-dream.jpg';
import badDream3d from 'assets/img/works/bad-dream-3d.jpg';
import bouncingBall from 'assets/img/works/bouncing-ball.jpg';
import ringstone from 'assets/img/works/ringstone.jpg';

import theSea from 'assets/mp3/the-sea.mp3';

import WorkItem from './WorkItem';

import './RecentWorks.scss';

const controller = new ScrollMagic.Controller();

function RecentWorks() {
  const { t } = useTranslation();

  return (
    <div id="recent-works">
      <Container>
        <h3>{t('RECENT_WORKS')}</h3>

        <Row>
          <Col xs={12} md={4}>
            <WorkItem
              image={orgChart}
              title="Organization Chart"
              category={CATEGORIES.WEB}
              controller={controller}
              ratio={1.2}
              description="Mini project for an organization chart editor (2020)"
              url="https://orgchart.cuongxoi.com"
            />
            <WorkItem
              image={asbnMv}
              title="XOI - Ánh Sáng Ban Ngày"
              category={CATEGORIES.FILM}
              controller={controller}
              ratio={0.6}
              description="Music video (2022)"
              url="https://youtu.be/N2DWGhPfPOw"
            />
            <WorkItem
              image={ringstone}
              title="The Sea"
              category={CATEGORIES.SOUND}
              controller={controller}
              ratio={1.2}
              description="Original soundtrack (2015)"
              source={theSea}
            />
          </Col>
          <Col xs={12} md={4}>
            <WorkItem
              image={magicBoard}
              title="Magic Board"
              category={CATEGORIES.WEB}
              controller={controller}
              ratio={0.7}
              description="Mini project with animation on a grid (2020)"
              url="https://magicboard.cuongxoi.com"
            />
            <WorkItem
              image={wabisabi}
              title="The Underdogs 2: Wabi-sabi"
              category={CATEGORIES.ARTWORK}
              controller={controller}
              ratio={1.1}
              description="Artwork for a rock show (2019)"
            />
            <WorkItem
              image={bouncingBall}
              title="Bouncing ball"
              category={CATEGORIES.WEB}
              controller={controller}
              description="A simple mini game using WebGL (2013)"
              url="https://dinosaur.cuongxoi.com/bouncing_ball/"
            />
          </Col>
          <Col xs={12} md={4}>
            <WorkItem
              image={asbnbook}
              title="XOI - Ánh Sáng Ban Ngày"
              category={CATEGORIES.WRITTING}
              controller={controller}
              ratio={0.5}
              description="A novella"
              url="https://www.xoi.vn/shop/anh-sang-ban-ngay-novella"
            />
            <WorkItem
              image={badDream}
              title="XOI - Mơ"
              category={CATEGORIES.FILM}
              controller={controller}
              ratio={0.5}
              description="Music video (2017)"
              url="https://youtu.be/8QGuB4DJ9U8"
            />
            <WorkItem
              image={badDream3d}
              title="3D Low Poly Deer"
              category={CATEGORIES.THREED}
              controller={controller}
              description="3D deer low poly style with movement rigged (2017)"
              url="https://youtu.be/RyZoG_ubXQ8"
            />
            <WorkItem
              image={badDream}
              title="XOI - Mơ (2017)"
              category={CATEGORIES.WRITTING}
              controller={controller}
              ratio={0.6}
              description="Short story"
              url="https://www.xoi.vn/stories/mo"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RecentWorks;
