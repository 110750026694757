export const ACTION_TYPES = {
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_IS_TABLET: 'SET_IS_TABLET',
  SET_IS_DESKTOP: 'SET_IS_DESKTOP',
  SET_SCREEN_SIZE: 'SET_SCREEN_SIZE'
};

const mobileDetected = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_IS_MOBILE,
    val: true
  });

  dispatch({
    type: ACTION_TYPES.SET_IS_TABLET,
    val: false
  });

  dispatch({
    type: ACTION_TYPES.SET_IS_DESKTOP,
    val: false
  });
};

const tabletDetected = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_IS_MOBILE,
    val: false
  });

  dispatch({
    type: ACTION_TYPES.SET_IS_TABLET,
    val: true
  });

  dispatch({
    type: ACTION_TYPES.SET_IS_DESKTOP,
    val: false
  });
};

const desktopDetected = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_IS_MOBILE,
    val: false
  });

  dispatch({
    type: ACTION_TYPES.SET_IS_TABLET,
    val: false
  });

  dispatch({
    type: ACTION_TYPES.SET_IS_DESKTOP,
    val: true
  });
};

const setScreenSize = (value) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SCREEN_SIZE,
    val: value
  });
};

export default {
  mobileDetected,
  tabletDetected,
  desktopDetected,
  setScreenSize,
};
