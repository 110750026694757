import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import $ from 'jquery';

import { history } from 'helpers';
import { PATHS, DEVICE_TYPES } from 'consts';
import Header from 'components/Header';
import SoundPlayerModal from 'components/SoundPlayerModal';
import PictureModal from 'components/PictureModal';
import Home from 'views/Home';

import image from 'assets/img/cover-pic.jpg';

import appActions from './AppActions';
import './App.scss';

function App() {
  const title = 'Cuong, Nguyen Tan | XOI';
  const description = 'Programmer • Musician • Graphics Designer';

  const dispatch = useDispatch();

  useEffect(() => {
    const detectMobile = () => {
      if (process.browser) {
        const breakpoint = 768;
        const breakpointTablet = 992;
        const w = $(window).width();

        if (dispatch && (typeof dispatch === 'function')) {
          dispatch(appActions.setScreenSize(w));
          if (w < breakpoint) {
            dispatch(appActions.mobileDetected());
          } else if ((w >= breakpoint) && (w < breakpointTablet)) {
            dispatch(appActions.tabletDetected());
          } else if (w >= breakpointTablet) {
            dispatch(appActions.desktopDetected());
          }
        }

        if (w < breakpoint) {
          return DEVICE_TYPES.MOBILE;
        }

        if ((w >= breakpoint) && (w < breakpointTablet)) {
          return DEVICE_TYPES.TABLET;
        }

        if (w >= breakpointTablet) {
          return DEVICE_TYPES.DESKTOP;
        }
      } else {
        dispatch(appActions.desktopDetected());
        return DEVICE_TYPES.DESKTOP;
      }

      return DEVICE_TYPES.DESKTOP;
    };

    detectMobile();

    if (process.browser) {
      $(window).on('resize', detectMobile);
    }

    return () => {
      $(window).off('resize', detectMobile);
    };
  }, [dispatch]);

  return (
    <div id="app">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="cuongxoi, cuong xoi, cuong nguyen tan, cuong nguyentan, nguyen tan cuong, cường xoi, cường nguyễn tấn, nguyễn tấn cường" />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <meta itemProp="title" content={title} />
        <meta itemProp="description" content={description} />
        <meta itemProp="image" content={image} />
      </Helmet>

      <Header />

      <Router history={history}>
        <Switch location={history.location}>
          <Route path={PATHS.HOME} component={Home} />
          <Redirect to={PATHS.HOME} />
        </Switch>
      </Router>

      <SoundPlayerModal />
      <PictureModal />
    </div>
  );
}

export default App;
