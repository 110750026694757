/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import HistoryTree from 'components/HistoryTree';
import profilePic from 'assets/img/profile-pic.jpg';

import './About.scss';

const exp = [
  {
    date: 'Nov, 2020 - now',
    title: 'Principal Developer - Lestricolores',
    description: 'Lestricolres is a domiciliation service based in Paris. I\'m currently working here as a principal developer.',
    url: 'https://www.lestricolores.fr'
  },
  {
    date: 'June, 2020 - now',
    title: 'Lead Engineer/Tech Advisor - Camdonhanh',
    description: 'Camdonhanh is a service that offers secured loans. I worked here as a lead enginneer, managed a team of 3 members. Now I\'m working as a technical advisor.',
    url: 'https://www.camdonhanh.vn'
  },
  {
    date: 'Oct, 2018 - May, 2020',
    title: 'Principal Developer - JobHop',
    description: 'JobHop is a SaaS for recruitment and candidate management. I worked here as principal developer and managed a team of 10 members.',
    url: 'https://www.jobhop.vn'
  },
  {
    date: 'Jan, 2018 - Jun, 2019',
    title: 'Senior Fullstack Developer - Nesttech VN',
    description: 'Nesttech VN is a capital venture fund that also develops their own products. I worked as a senior fullstack developer here, helped planned product design and mentored junior members.',
    url: 'http://vn-nest.tech'
  },
  {
    date: 'Jun - Dec, 2017',
    title: 'Fullstack Developer - Redixel Inc.',
    description: 'Redixel is a digital agency team that builds website solutions. They have very high standards on frontend appearance & animation. I worked here as a Fullstack Developer for 6 months.',
    url: 'https://www.redixel.com'
  },
  {
    date: 'Sep, 2016 - Jul, 2018',
    title: 'Fullstack Developer - RustyCompass',
    description: 'RustyCompass is a small team of enthusiasts that loves Indochina\'s culture & history. Their products include Vietnam, Laos & Cambodia travel guides, travel tours, 60s themed Café. I worked here as a Fullstack Developer, helped with Graphics Designs and ocasionally played live music.',
    url: 'https://www.rustycompass.com'
  },
  {
    date: 'Aug, 2015 - Nov, 2016',
    title: 'Frontend Developer - AsiaInnovations',
    description: 'I worked on a product of AI called Funpepper. It\'s a web platform that contains many minigames that generates content from users\' social info. I worked as a Frontend Developer.',
    url: 'https://www.funpepper.com'
  },
  {
    date: 'Jul, 2013 - Dec, 2014',
    title: 'Fullstack Developer - Up English',
    description: 'UP English is a startup project formed by a small team of students in my university. We developed a platform that allowed users to study English using flashcards & minigames. I worked as Fullstack Developer here.',
    url: 'http://www.upenglish.vn'
  },
  {
    date: '2011 - now',
    title: 'Fullstack Developer - Freelance',
    description: 'I have some clients from word of mouth and started my freelance service when I was still in school. I worked as Fullstack Developer and Graphics/UI/UX Designer'
  },
  {
    date: '2013 - now',
    title: 'Musician, Manager - XOI Music',
    description: 'I manage, write and perform original music with a band of 5 members.'
  },
  {
    date: '2013 - now',
    title: 'Sound Engineer - Freelance',
    description: 'I started and played drums for a band with my high school mates. Eventually broke up, I started my own musical project and have my own home recording studio.'
  }
];

const edu = [
  {
    date: '2009 - 2014',
    title: 'Bachelor Degree of Computer Science - International University, Ho Chi Minh City',
    description: 'I graduated with a thesis on online public health records system.'
  },
  {
    date: '2006 - 2009',
    title: 'Class of Computer Science - Thang Long High School for the Gifted, Da Lat City',
    description: 'I got accepted with highest entrance test score on Programming subject. Got selected every year for the school team to participate in national & local programming contests.'
  },
  {
    date: '2004 - 2009',
    title: 'Computer Science - Mr. Tuan Nguyen Ngoc, Da Lat City',
    description: 'I started programming with Mr. Tuan. He taught me algorithms & data structure and most importantly, he taught me how to teach myself. Which helps me accquire a lot of knowledge later in my career.'
  },
  {
    date: '2008',
    title: 'Static Web Development - Da Lat City',
    description: 'I had a few static web development lessons with developers in an outsourcing company based near my home in Da Lat City. Back then they still used HTML4 & CSS2. After that, I taught myself dynamic web development with ASP 3.0 & MySQL 5.0.'
  },
  {
    date: '2012 - now',
    title: 'Vocal - Mr. Viet Vo Anh, Ho Chi Minh City',
    description: 'I\'ve been having professional vocal lessons with Mr. Viet for many years. He taught me how to speak & sing using correct vocal techniques. Most importantly, he taught me the high quality mindset that I apply in everything that I do.'
  },
  {
    date: '2011',
    title: 'Guitar - IGM Music Class, Ho Chi Minh City',
    description: 'I\'d taken guitar lessons several times before and learned proper guitar techniques for 3 months in IGM class with Mr. Huy. I kept on learning and teaching myself guitar until today.'
  },
  {
    date: '2010',
    title: 'Piano - MPU Music School, Ho Chi Minh City',
    description: 'I learned basic piano for 3 months in MPU school with Ms. Thuong. I kept on learning and teaching myself piano until today.'
  },
  {
    date: '2007',
    title: 'Drums - Church of Don Bosco, Da Lat City',
    description: 'Even though I\'m a pantheist, I learned basic drumming for 2 months in a Catholic Church with Mr. Luan. I kept on learning & teaching myself drumming until today.'
  },
];

const awards = [
  {
    date: '2009',
    title: 'Programming Contest for Gifted Students',
    description: '3rd Place - Da Lat City'
  },
  {
    date: '2008',
    title: 'Olympiad April 30th Programming Contest',
    description: 'Silver Medal - Ho Chi Minh City'
  },
  {
    date: '2007',
    title: 'Programming Contest for Gifted Students',
    description: '1st Place - Da Lat City'
  },
  {
    date: '2006',
    title: 'Programming Contest for Young Talents',
    description: '2nd Place - Da Lat City'
  },
];

function About() {
  const { t } = useTranslation();

  return (
    <div id="about">
      <Container>
        <h3>{t('ABOUT_ME')}</h3>
        <br />
        <div className="profile-pic">
          <img alt={t('CUONG_NGUYENTAN')} src={profilePic} />
        </div>
        <div className="desc">
          <p dangerouslySetInnerHTML={{ __html: t('HI_IM_CUONG') }} />
        </div>

        <Row>
          <Col xs={12} md={6}>
            <h3>{t('EXPERIENCE')}</h3>
            <HistoryTree items={exp} />
          </Col>
          <Col xs={12} md={6}>
            <h3>{t('EDUCATION')}</h3>
            <HistoryTree items={edu} />

            <h3>{t('AWARDS')}</h3>
            <HistoryTree items={awards} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
