import React from 'react';
import PropTypes from 'prop-types';

import './HistoryItem.scss';

function HistoryItem({ date, title, description, url }) {
  return (
    <div className="history-item">
      <div className="date">{ date }</div>
      { !!url && <a href={url} rel="noopener noreferrer" target="_blank"><h4>{ title }</h4></a> }
      { !url && <h4>{ title }</h4> }
      <p>{ description }</p>
    </div>
  );
}

HistoryItem.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string
};

HistoryItem.defaultProps = {
  url: ''
};

export default HistoryItem;
