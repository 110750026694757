import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Logo.scss';

const Logo = ({ mode }) => {
  const logoClass = classNames({
    logo: true,
    [mode]: true
  });

  return (
    /* eslint-disable */
    <div className={ logoClass }>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2015 2015"><defs></defs><title>xoi__logo_4_final</title><g id="xoi." className="cls-1"><g id="xoi.-2" data-name="xoi."><path className="cls-2" d="M1037.76,753.48q-27.46,0-37.27,22.26T990.68,853v416.24q0,55,9.81,77.23t37.27,22.26q27.46,0,37.27-22.26t9.81-77.23V853q0-55-9.81-77.23T1037.76,753.48ZM1007.5,0C451.07,0,0,451.07,0,1007.5,0,1254.65,89,1481,236.7,1656.3L115,1778H358.33c175.34,147.88,401.84,237,649.17,237,556.43,0,1007.5-451.07,1007.5-1007.5S1563.93,0,1007.5,0ZM808.92,1428.91l-79.78-260.49-69.31,260.49H584l116.39-379.6L585.29,686.73h77.16L734.37,925l69.31-238.23h79.78L763.14,1050.62l122.93,378.29Zm354.38-184.57q0,55-5.88,92.29t-20.93,60.21a88,88,0,0,1-39.23,33.38q-24.21,10.46-59.5,10.47-71.94,0-98.73-44.51t-26.81-151.84V877.84Q912.22,770.51,939,726t98.73-44.51q35.31,0,59.5,10.47a88.3,88.3,0,0,1,39.23,33.38q15,22.92,20.93,60.21t5.88,92.29Zm163.47,184.57H1248.3V686.73h78.47ZM1332,572.85q-18.33,14.42-43.16,14.4-27.45,0-41.19-17T1233.92,527q0-24.85,19-38a71,71,0,0,1,41.2-13.09q22.22,0,39.23,17.67t17,37.3Q1350.31,558.45,1332,572.85Zm168,856.06a71.2,71.2,0,0,1-41.19,13.09q-22.26,0-39.24-17.67t-17-37.31q0-27.48,18.31-41.89t43.16-14.39q27.47,0,41.19,17T1519,1391Q1519,1415.82,1500,1428.91Z"/></g></g></svg>
    </div>
    /* eslint-enable */
  );
};

Logo.propTypes = {
  mode: PropTypes.string
};

Logo.defaultProps = {
  mode: ''
};

export default Logo;
