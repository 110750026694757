export const PATHS = {
  HOME: '/'
};

export const CATEGORIES = {
  IMAGE: 'IMAGE',
  WEB: 'WEB',
  ARTWORK: 'ARTWORK',
  MUSIC: 'MUSIC',
  SOUND: 'SOUND',
  FILM: 'FILM',
  THREED: 'THREED',
  WRITTING: 'WRITTING'
};

export const MODAL_NAMES = {
  SOUND_PLAYER: 'SOUND_PLAYER',
  PICTURE: 'PICTURE'
};

export const DEVICE_TYPES = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP'
};

export default {};
