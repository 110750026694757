import { ACTION_TYPES } from 'actions/modal';

const INIT_STATE = {
  activeModal: null
};

const modalReducer = (state = INIT_STATE, action) => {
  const { type, name, val } = action;
  let obj = { ...state };

  switch (type) {
    case ACTION_TYPES.SET_ACTIVE_MODAL:
      if (state.activeModal === name) {
        if ((typeof val === 'undefined')
        || ((typeof val === 'boolean') && !val)) {
          obj = {
            ...state,
            activeModal: null
          };
        }
      } else {
        if ((typeof val === 'undefined')
        || ((typeof val === 'boolean') && val)) {
          obj = {
            ...state,
            activeModal: name
          };
        }
      }
      return obj;

    default:
      return state;
  }
};

export default modalReducer;
