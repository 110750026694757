import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { MODAL_NAMES } from 'consts';
import { modalAction } from 'actions';

import './SoundPlayerModal.scss';

function SoundPlayerModal({ onHide }) {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modal.activeModal === MODAL_NAMES.SOUND_PLAYER);
  const { source, title, description } = useSelector((state) => state.workItem.targetingSound);

  const close = () => {
    onHide();
    dispatch(modalAction.toggleModal(MODAL_NAMES.SOUND_PLAYER, false));
  };

  return (
    <Modal id="sound-player-modal" centered show={show} onHide={close}>
      <Modal.Body>
        <div className="player">
          <audio controls>
            <source src={source} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>

          <h5>{title}</h5>
          <p>{ description }</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

SoundPlayerModal.propTypes = {
  onHide: PropTypes.func
};

SoundPlayerModal.defaultProps = {
  onHide: () => {}
};

export default SoundPlayerModal;
