import React from 'react';
import PropTypes from 'prop-types';

import HistoryItem from './HistoryItem';

import './HistoryTree.scss';

function HistoryTree({ items }) {
  return (
    <div className="history-tree">
      { items.map((item) => (
        <HistoryItem key={`${item.date}-${item.title}`} date={item.date} title={item.title} description={item.description} url={item.url} />
      )) }
    </div>
  );
}

HistoryTree.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(HistoryItem.propTypes)).isRequired
};

export default HistoryTree;
