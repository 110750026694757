import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import classNames from 'classnames';

import Logo from 'icons/Logo';
import { history, scrollTo } from 'helpers';

import './Header.scss';

const menuItems = [
  {
    text: 'HOME',
    route: '/'
  },
  {
    text: 'ABOUT',
    route: '/about'
  },
  {
    text: 'PORTFOLIO',
    route: '/what-i-do'
  },
  {
    text: 'CONTACTS',
    route: '/contacts'
  }
];

function Header() {
  const { t } = useTranslation();
  const [isMenuActive, setMenuActive] = useState(false);

  const menuIconClass = classNames({
    'menu-icon': true,
    active: isMenuActive
  });

  const navMenuClass = classNames({
    'nav-menu': true,
    active: isMenuActive
  });

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const goToAnchor = (route = '/') => {
    const id = `#${route.replace(/\//g, '')}`;

    let y = 0;

    try {
      const elem = $(id);
      if (elem.length) {
        y = elem.offset().top - 80;
      }
    } catch (e) {
      y = 0;
    }

    scrollTo(y);
    history.replace(route);
    setMenuActive(false);
  };

  return (
    <header>
      <div className="logo-wrapper" role="button" tabIndex={0} onClick={() => goToAnchor()}>
        <Logo />
      </div>

      <div className="right">
        <ul className={navMenuClass}>
          { menuItems.map((item) => (
            <li key={item.text} onClick={() => goToAnchor(item.route)}>{t(item.text)}</li>
          )) }
        </ul>

        <button
          type="button"
          className={menuIconClass}
          onClick={toggleMenu}
        >
          <div className="line line-1" />
          <div className="line line-2" />
          <div className="line line-3" />
        </button>
      </div>
    </header>
  );
}

export default Header;
