import { ACTION_TYPES } from './AppActions';

export const initState = {
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  screenSize: 0
};

const reducer = (state = initState, action) => {
  switch(action.type) {
    case ACTION_TYPES.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.val
      };

    case ACTION_TYPES.SET_IS_TABLET:
      return {
        ...state,
        isTablet: action.val
      };

    case ACTION_TYPES.SET_IS_DESKTOP:
      return {
        ...state,
        isDesktop: action.val
      };

    case ACTION_TYPES.SET_SCREEN_SIZE:
      return {
        ...state,
        screenSize: action.val
      };

    default:
      return state;
  }
};

export default reducer;
