/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef, useState } from 'react';
import Slick from 'react-slick';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Typed from 'react-typed';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { TweenMax, Power1 } from 'gsap';
import classNames from 'classnames';

import fullstack from 'assets/img/bgs/fullstack.jpg';
import drums from 'assets/img/bgs/drums.jpg';
import vocal from 'assets/img/bgs/vocal.jpg';
import devices from 'assets/img/bgs/devices.jpg';
import drumset from 'assets/img/bgs/drumset.jpg';
import recording from 'assets/img/bgs/recording.jpg';

import './Intro.scss';

const imgs = [
  {
    alt: 'Fullstack Developer',
    src: devices
  },
  {
    alt: 'Fullstack Developer',
    src: fullstack
  },
  {
    alt: 'Drummer',
    src: drumset
  },
  {
    alt: 'Drummer',
    src: drums
  },
  {
    alt: 'Sound Engineer',
    src: recording
  },
  {
    alt: 'Vocalist',
    src: vocal
  }
];

function Intro({ children }) {
  const { t } = useTranslation();
  const wrapperRef = useRef();
  const slickRef = useRef();
  const [freeze, setFreeze] = useState(false);

  const titleClass = classNames({
    glitch: true,
    name: true,
    animate: !freeze
  });

  useEffect(() => {
    const scrollChildren = () => {
      if (!wrapperRef || !wrapperRef.current) return;
      if (!slickRef || !slickRef.current) return;

      const top = $(window).scrollTop();

      if (top > window.innerHeight) {
        slickRef.current.slickPause();
        setFreeze(true);
      } else {
        slickRef.current.slickPlay();
        setFreeze(false);
      }

      TweenMax.to(wrapperRef.current, .8, {
        ease: Power1.easeOut,
        y: -top
      });
    };

    const offsetScroll = () => {
      const w = window.innerWidth;
      let offset;

      if (w >= 992) {
        offset = 275;
      } else if (w >= 768 && w < 992) {
        offset = 450;
      } else {
        offset = 750;
      }

      TweenMax.set(wrapperRef.current, {
        y: 0
      });

      TweenMax.set('body', {
        height: wrapperRef.current.scrollHeight + offset
      });
    };

    $(window).on('scroll', scrollChildren);
    $(window).on('resize', offsetScroll);
    offsetScroll();

    return () => {
      $(window).off('scroll', scrollChildren);
      $(window).off('resize', offsetScroll);
      TweenMax.set('body', {
        height: null
      });
    };
  }, []);

  return (
    <div id="intro">
      <Slick
        infinite
        speed={1000}
        slidesToShow={1}
        slidesToScroll={1}
        fade
        cssEase="linear"
        autoplay
        autoplaySpeed={3000}
        ref={slickRef}
        arrows={false}
      >
        { imgs.map((img) => (
          <div className="bg" key={img.src}>
            <img alt={img.alt} src={img.src} />
          </div>
        )) }
      </Slick>

      <div className="overlay" />
      <div className="overlay-2" />

      <div className="main-wrapper">
        <Container className="main">
          <h1 className={titleClass}>
            {t('CUONG_NGUYENTAN')}
            <div className="before">{t('CUONG_NGUYENTAN')}</div>
            <div className="after">{t('CUONG_NGUYENTAN')}</div>
          </h1>
          <br />
          <Typed
            strings={[
              'Programming since 2004',
              'Music since 2007',
              'Graphics since 2008',
              'And still learning.'
            ]}
            typeSpeed={30}
            loop
            startDelay={1000}
          />
        </Container>

        <div className="main-inner-wrapper" ref={wrapperRef}>
          <div className="children">
            { children }
          </div>
        </div>
      </div>
    </div>
  );
}

Intro.propTypes = {
  children: PropTypes.node
};

Intro.defaultProps = {
  children: null
};

export default Intro;
