import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { MODAL_NAMES } from 'consts';
import { modalAction } from 'actions';

import './PictureModal.scss';

function PictureModal({ onHide }) {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modal.activeModal === MODAL_NAMES.PICTURE);
  const { source, title, description } = useSelector((state) => state.workItem.targetingPicture);

  const close = () => {
    onHide();
    dispatch(modalAction.toggleModal(MODAL_NAMES.PICTURE, false));
  };

  return (
    <Modal id="picture-modal" centered show={show} onHide={close}>
      <Modal.Body>
        <div className="picture">
          <img src={source} alt={title} />

          <h5>{title}</h5>
          <p>{ description }</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

PictureModal.propTypes = {
  onHide: PropTypes.func
};

PictureModal.defaultProps = {
  onHide: () => {}
};

export default PictureModal;
