/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faFilmAlt } from '@fortawesome/pro-regular-svg-icons';
import { faMusic } from '@fortawesome/free-solid-svg-icons';
import { faBrush, faWaveformPath, faTypewriter } from '@fortawesome/pro-duotone-svg-icons';

import './What.scss';

const whats = [
  {
    icon: faCode,
    title: 'Web Development',
    description: 'From simple landing page to CMS system or large scale web app, I\'m capable of every step of the building process.'
  },
  {
    icon: faMusic,
    title: 'Music',
    description: 'I\'m a multi-instrumentalist. I can play drums, guitar, bass, piano, synthezizer and sing at intermediate level.'
  },
  {
    icon: faWaveformPath,
    title: 'Sound Engineering',
    description: 'I compose, record instruments, mix and design soundtracks for bands, artists, video games, video clips, movies, etc.'
  },
  {
    icon: faBrush,
    title: 'Graphics Design',
    description: 'I create logos, posters, artworks, edit/enhace photos. I can also work on some simple digital painting and basic 3D modeling.'
  },
  {
    icon: faFilmAlt,
    title: 'Film',
    description: 'I can script, direct, shoot and edit short films, including digital effects at basic level.'
  },
  {
    icon: faTypewriter,
    title: 'Writing',
    description: 'I write short stories and am working on a full novel. I can write well in both Vietnamese & English.'
  }
];

const skills = [
  {
    name: 'Algorithm & Data Structure',
    percentage: 90
  },
  {
    name: 'Front-end Web Development',
    percentage: 95
  },
  {
    name: 'Back-end Web Development',
    percentage: 90
  },
  {
    name: 'Mobile Development',
    percentage: 20
  },
  {
    name: 'DevOps',
    percentage: 75
  },
  {
    name: 'Architecture & Database',
    percentage: 85
  },
  {
    name: 'Graphics Design',
    percentage: 70
  },
  {
    name: 'UI/UX Design',
    percentage: 70
  },
  {
    name: 'Digital Painting',
    percentage: 40
  },
  {
    name: 'Music Theory',
    percentage: 70
  },
  {
    name: 'Sound Engineering',
    percentage: 80
  }
];

function What() {
  const { t } = useTranslation();
  const { isMobile } = useSelector((state) => state.app);

  return (
    <div id="what-i-do">
      <Container>
        <h3>{t('WHAT_I_DO')}</h3>
        <br />

        <Row>
          { whats.map((item) => (
            <Col xs={12} md={4} key={item.title}>
              <div className="what-item">
                <div className="title">
                  <FontAwesomeIcon icon={item.icon} />
                  <h4>{item.title}</h4>
                </div>
                <p>{item.description}</p>
              </div>
            </Col>
          )) }
        </Row>

        <div className="skills">
          { skills.map((skill) => (
            <div className="skill-wrapper" key={`${skill.name}`}>
              <div className="rail" />
              <div className="car" style={{ width: `${skill.percentage}%` }}>
                { !isMobile && <div className="percentage">{ `${skill.percentage}%` }</div> }
              </div>
              <div className="text">{ `${skill.name}${isMobile ? ` (${skill.percentage}%)` : ''}` }</div>
            </div>
          )) }
        </div>
      </Container>
    </div>
  );
}

export default What;
