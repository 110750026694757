import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faEnvelopeOpenText, faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons';

import './Contacts.scss';

function Contacts() {
  const { t } = useTranslation();

  return (
    <div id="contacts">
      <Container>
        <h3>{t('CONTACTS_INFO')}</h3>

        <Row>
          <Col xs="12" md="4" className="contact-item">
            <div className="title">
              <FontAwesomeIcon icon={faMobile} />
              <span>{ t('PHONE') }</span>
            </div>
            <p>(+84) 358 556 015</p>
          </Col>

          <Col xs="12" md="4" className="contact-item">
            <div className="title">
              <FontAwesomeIcon icon={faEnvelopeOpenText} />
              <span>{ t('EMAIL') }</span>
            </div>
            <p><a href="mailto:cuongxoi@cuongxoi.com">cuongxoi@cuongxoi.com</a></p>
          </Col>

          <Col xs="12" md="4" className="contact-item">
            <div className="title">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>{ t('ADDRESS') }</span>
            </div>
            <p>Le Van Luong, Tan Hung, District 7, Ho Chi Minh City</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contacts;
