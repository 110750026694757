import { ACTION_TYPES } from './WorkItemAction';

const INIT_STATE = {
  targetingSound: {
    source: null,
    title: '',
    description: ''
  },
  targetingPicture: {
    source: null,
    title: '',
    description: ''
  }
};

const workItemReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_TARGETING_SOUND:
      return {
        ...state,
        targetingSound: {
          source: action.source,
          title: action.title,
          description: action.description
        }
      };

    case ACTION_TYPES.SET_TARGETING_PICTURE:
      return {
        ...state,
        targetingPicture: {
          source: action.source,
          title: action.title,
          description: action.description
        }
      };

    default:
      return state;
  }
};

export default workItemReducer;
