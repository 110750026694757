import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import app from 'AppReducer';
import modal from 'reducers/modal';
import workItem from 'views/Home/RecentWorks/WorkItem/WorkItemReducer';

const reducers = {
  app,
  modal,
  workItem
};

const rootReducer = combineReducers(reducers);

const persistConfig = {
  key: `cuongxoi.${process.env.REACT_APP_STORE_KEY}`,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['app', 'modal', 'workItem']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  applyMiddleware(
    thunkMiddleware
  )
);

export const persistor = persistStore(store);
