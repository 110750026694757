import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faMusic } from '@fortawesome/free-solid-svg-icons';
import { faCode, faFilmAlt } from '@fortawesome/pro-regular-svg-icons';
import { faBrush, faWaveformPath, faCube, faTypewriter } from '@fortawesome/pro-duotone-svg-icons';
import * as ScrollMagic from 'scrollmagic';
import { TimelineMax, Linear } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { useDispatch } from 'react-redux';

import { CATEGORIES, MODAL_NAMES } from 'consts';
import { modalAction } from 'actions';

import workItemAction from './WorkItemAction';
import './WorkItem.scss';

ScrollMagicPluginGsap(ScrollMagic, TimelineMax);

const WorkItem = ({ title, image, description, category, url, source, ratio, controller }) => {
  const [icon, setIcon] = useState(faImage);
  const imageRef = useRef(null);
  const workItemRef = useRef(null);
  const dispatch = useDispatch();

  const showOff = () => {
    if (url) {
      window.open(url, '_blank');
      return;
    }

    switch (category) {
      case CATEGORIES.SOUND:
        if (source) {
          dispatch(workItemAction.setTargetingSound(source, title, description));
          dispatch(modalAction.toggleModal(MODAL_NAMES.SOUND_PLAYER, true));
        }
        break;

      case CATEGORIES.ARTWORK:
        if (image) {
          dispatch(workItemAction.setTargetingPicture(image, title, description));
          dispatch(modalAction.toggleModal(MODAL_NAMES.PICTURE, true));
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (category) {
      case CATEGORIES.WEB:
        setIcon(faCode);
        break;

      case CATEGORIES.ARTWORK:
        setIcon(faBrush);
        break;

      case CATEGORIES.SOUND:
        setIcon(faWaveformPath);
        break;

      case CATEGORIES.MUSIC:
        setIcon(faMusic);
        break;

      case CATEGORIES.FILM:
        setIcon(faFilmAlt);
        break;

      case CATEGORIES.THREED:
        setIcon(faCube);
        break;

      case CATEGORIES.WRITTING:
        setIcon(faTypewriter);
        break;

      case CATEGORIES.IMAGE:
      default:
        setIcon(faImage);
        break;
    }
  }, [category]);

  useEffect(() => {
    if (!controller) return;
    if (!workItemRef || !workItemRef.current) return;
    if (!imageRef || !imageRef.current) return;

    const tl = new TimelineMax();
    tl.to(imageRef.current, 1, { y: -180, ease: Linear.easeNone });

    const scene = new ScrollMagic.Scene({
      triggerElement: workItemRef.current,
      triggerHook: 0.4,
      duration: '100%'
    });

    scene.setTween(tl).addTo(controller);
  }, [workItemRef, imageRef, controller]);

  return (
    <div className="work-item" ref={workItemRef}>
      <div className="image-wrapper" style={{ paddingBottom: `${ratio * 100}%` }} role="button" tabIndex={0} onClick={() => showOff()}>
        <div className="image-inner-wrapper" ref={imageRef}>
          <img src={image} alt={title} />
        </div>
        <div className="overlay" />
        <div className="overlay pink" />
        <FontAwesomeIcon icon={icon} />
      </div>

      { !!url && <a href={url} target="_blank" rel="noopener noreferrer"><h4>{ title }</h4></a> }
      { !url && <h4>{ title }</h4> }
      { !!description && <p>{ description }</p> }
    </div>
  );
};

WorkItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  category: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  ratio: PropTypes.number,
  controller: PropTypes.instanceOf(ScrollMagic.Controller),
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

WorkItem.defaultProps = {
  category: CATEGORIES.IMAGE,
  url: '',
  description: '',
  ratio: 1,
  controller: null,
  source: null
};

export default WorkItem;
